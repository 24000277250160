import React,{useState,useEffect} from 'react'
import  '../components/bootstrap.min.css';

import loadable from '@loadable/component'
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
// const Footer = loadable(() => import('../components/Footer'))
// const Nav = loadable(() => import ('../components/Nav'))
// const TopNav = loadable(() => import ('../components/TopNav'))

function Community() {
  const  [user, setUser] = useState("")
  useEffect(() => {
  
      setUser(localStorage.getItem("token"));
  
  })
  return (
    <>  
      {/* <TopNav/> */}
      <Nav/>
      <section className="bg-light-gray padding-35px-tb page-title-small top-space-verum">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8 col-md-6 d-flex flex-column justify-content-center">
                        <h1 className="alt-font text-extra-dark-gray font-weight-600 no-margin-bottom">Community</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-70px-tb">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-12 col-lg-12 md-margin-50px-bottom sm-margin-30px-bottom slide-right">
                      <h5 className="title-section-alt alt-font">Connect with the Dezyne community</h5>
                      <p>As a company supporting and extending the Dezyne language and tools, our team needs to interact beyond the Verum employee base. Your feedback, your request for features, the discussions you participate in and cases shared, will help our team to improve Dezyne continuously and create the best possible solutions for your work.</p>
                      <h5 className="title-section-alt alt-font">Forum</h5>
                      <p>Discuss, learn, find advice, give advice, and ask your questions: about Dezyne and the concepts supporting Dezyne. Our <a className="text-green" href="https://forum.verum.com/" target="_blank"><u>forum</u></a> is a platform, freely accessible for everybody that is interested in formal methods used for software engineering applied in our toolset.</p>
                      <h5 className="title-section-alt alt-font">More Resources</h5>
                      <p>Getting started with the Dezyne? Look for our detailed <a className="text-green" href="http://download.verum.com/dezyne/manual/dezyne/dezyne.html" target="_blank"><u>documentation</u></a> &#8211; also available for the <a className="text-green" href="http://download.verum.com/dezyne-ide/manual/dezyne-ide/dezyne-ide.html" target="_blank"><u>IDE</u></a> and check the Dezyne <a className="text-green" href="http://download.verum.com/dezyne/manual/dezyne-tutorial/dezyne-tutorial.html" target="_blank"><u>tutorials</u></a> for a hands-on walk-through.<br />For the status of issues see <a className="text-green" href="https://gitlab.com/dezyne/dezyne-issues/-/issues" target="_blank"><u>Gitlab</u></a> and when you are looking for more interaction join #dezyne on <a className="text-green" href="https://freenode.net/" target="_blank"><u>Freenode</u></a>.</p>
                  </div>
              </div>                               
          </div>
      </section>
      <Footer/>
    </>
    
  );
}

export default Community;
